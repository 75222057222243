import * as React from "react";
import { graphql, Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import Collapsible from "react-collapsible";
import {
  jobsGrid,
  jobContent,
  jobBlock,
  jobList,
} from "../../scss-modules/jobs.module.scss";
import SectionIntro from "../../components/SectionIntro";
import { grid } from "../../scss-modules/contact-us.module.scss";

import ContactModule from "../../components/ContactModule";
const bannerImg = "../../banners/looking-for-work_banner.jpg";

//RENDER
export default function JobsPage({ data }) {
  const jobs = data.allPrismicJobs.nodes;

  return (
    <Layout pageTitle={"Looking for work?"}>
      <SplashBanner
        title={"Looking for work?"}
        content={"Contact us to grow your career with market leading clients."}
        backgroundImg={bannerImg}
      />
      <section id="jobBoard">
        {/*Contact modules*/}
        <section>
            <div className="container">
              <div className={`${grid} grid`}>
                <ContactModule
                  buttonHref="mailto:cvsubmit@performance-energy.com?subject=Registering my CV"
                  buttonText="Submit CV"
                  iconSrc="../../icons/font-awesome/register-profile.svg"
                  subText="Send us your profile for the first time."
                  title="Register your profile"
                />

                <ContactModule
                  buttonHref="mailto:cvsubmit@performance-energy.com?subject=Updating your records"
                  buttonText="Update CV"
                  iconSrc="../../icons/font-awesome/update-records.svg"
                  subText="Send us your updated CV so that we can update our records."
                  title="Update our records"
                />
              </div>
            </div>
          </section>
        <div className="container">
          <SectionIntro>
            <h3>Our Latest Vacancies</h3>
            <p>Opportunities globally, with clients of all sizes.</p>
          </SectionIntro>

          {jobs.map((item, i) => {
            return (
              <div key={i}>
                <Collapsible trigger={[`${item.data.job_title.text}`]}>
                  <div className={jobContent}>
                    <div className={`${jobsGrid} grid`}>
                      <div className="grid__column">
                        <ul className={jobList}>
                          <li>
                            <strong>Location: </strong>
                            {item.data.location.text}
                          </li>
                          <li>
                            <strong>Start Date: </strong>
                            {item.data.start_date.text}
                          </li>
                          <li>
                            <strong>Duration: </strong>
                            {item.data.duration.text}
                          </li>
                        </ul>
                      </div>
                      <div className="grid__column">
                        <ul className={jobList}>
                          <li>
                            <strong>Rotation: </strong>
                            {item.data.rotation.text}
                          </li>
                          <li>
                            <strong>Salary / Pay Rate: </strong>
                            {item.data.salary_pay_rate.text}
                          </li>
                          <li>
                            <strong>Employment Type: </strong>
                            {item.data.employment_type.text}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={jobBlock}>
                      <h4>Job Overview:</h4>
                      <RichText render={item.data.job_overview.richText} />
                    </div>
                    <div className={jobBlock}>
                      <h4>Minimum Requirements:</h4>
                      <RichText
                        render={item.data.minimum_requirements.richText}
                      />
                    </div>
                    <a
                      href={item.data.job_link.url}
                      target={item.data.job_link.target}
                      className={"button primary"}
                    >
                      Apply for job
                    </a>
                  </div>
                </Collapsible>
              </div>
            );
          })}
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query GetJobs {
    allPrismicJobs {
      nodes {
        data {
          job_title {
            text
          }
          location {
            text
          }
          start_date {
            text
          }
          duration {
            text
          }
          rotation {
            text
          }
          salary_pay_rate {
            text
          }
          employment_type {
            text
          }
          job_overview {
            richText
          }

          minimum_requirements {
            richText
          }
          job_link {
            url
            target
          }
        }
        uid
      }
    }
  }
`;
